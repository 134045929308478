<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="订单编号">
              <a-input placeholder="请输入" v-model="orderNumber" allow-clear @pressEnter="initSearchData('search')" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="学生">
              <a-input
                placeholder="请输入学号"
                v-model="studentCode"
                allow-clear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="订单状态">
              <a-select
                placeholder="请选择"
                mode="multiple"
                allowClear
                @change="initSearchData('search')"
                v-model="orderStatusSet"
              >
                <a-select-option :value="item.value" v-for="(item, index) in orderStatusArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="是否申请到账">
              <a-select placeholder="请选择" allowClear @change="initSearchData('search')" v-model="remittance">
                <a-select-option :value="item.value" v-for="(item, index) in remittanceArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="支付方式">
              <a-select placeholder="请选择" allowClear @change="initSearchData('search')" v-model="paymentMethod">
                <a-select-option :value="item.value" v-for="(item, index) in payMethodArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="订单类型">
              <a-select
                show-search
                allowClear
                mode="multiple"
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="请选择"
                @change="initSearchData('search')"
                v-model="tags"
              >
                <a-select-option :value="item.value" v-for="(item, index) in orderTypeArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="组织(部门):">
              <a-tree-select
                allow-clear
                v-model="deptId"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="treeData"
                placeholder="请选择"
                tree-default-expand-all
              >
              </a-tree-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="创建人">
              <a-select
                show-search
                v-model="customerId"
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="请选择"
                @change="initSearchData('search')"
                @search="queryCreatorList"
                allowClear
              >
                <a-select-option :value="i.uuid" v-for="i of creatorList" :key="i.uuid">
                  {{ i.fullName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="订单创建时间">
              <a-range-picker @change="onChangeTableDate" v-model="queryClassDateArr" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="支付过期状态">
              <a-select
                allowClear
                option-filter-prop="children"
                placeholder="请选择"
                @change="initSearchData('search')"
                v-model="expired"
              >
                <a-select-option :value="item.value" v-for="(item, index) in paymentExpirationStatus" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="退款状态">
              <a-select
                allowClear
                option-filter-prop="children"
                placeholder="请选择"
                @change="initSearchData('search')"
                v-model="refundStatus"
              >
                <a-select-option :value="item.value" v-for="(item, index) in refundStatusArray" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" style="margin-right: 10px" @click="initSearchData">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-wrap">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :scroll="{ y: 600 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <a slot="level" slot-scope="text">{{ text }}</a>
        <span slot="studentCode" slot-scope="studentCode, record">
          <a @click="toStudentDetail(record)">{{ `${record.studentName}(${studentCode})` }}</a>
        </span>
        <template slot="userName" slot-scope="userName, record">
          <span style="font-weight: 600; font-size: 16px">{{ userName }}</span>
          <p>{{ record.role }} ({{ record.department }})</p>
        </template>
        <template slot="productTags" slot-scope="productTags, record">
          <span v-for="i in record.productTags" :key="i">
            {{
              initTabsList(i, tabsList).length !== 0 && ['CHINESE', 'MATH', 'ENGLISH'].find((item) => i == item)
                ? initTabsList(i, tabsList)[0].label
                : ' '
            }}
          </span>
        </template>
        <template slot="orderStatus" slot-scope="orderStatus">
          <span> {{ initTabsList(orderStatus, orderStatusArray)[0].label }} </span>
        </template>
        <template slot="approveStatus" slot-scope="approveStatus">
          <span> {{ initTabsList(approveStatus, approvalStatus)[0].label }} </span>
        </template>
        <template slot="paymentMethod" slot-scope="paymentMethod">
          <span>
            {{
              initTabsList(paymentMethod, payMethodArr).length > 0
                ? initTabsList(paymentMethod, payMethodArr)[0].label
                : ''
            }}
          </span>
        </template>
        <template slot="logisticsStatus" slot-scope="logisticsStatus">
          <span> {{ initTabsList(logisticsStatus, deliveryStatus)[0].label }} </span>
        </template>
        <template slot="money" slot-scope="money">
          <a-popover>
            <template v-slot:title>
              实时汇率<span style="font-size: 12px; color: rgba(0, 0, 0, 0.65)">（仅供银行转账参考）</span>
            </template>
            <template slot="content">
              <a-table
                :pagination="false"
                size="small"
                :columns="currencyColumns"
                :data-source="money.exChangeList"
                :rowKey="
                  (record, index) => {
                    return index;
                  }
                "
              >
              </a-table>
            </template>
            <span
              >{{ money.currency }} {{ money.value.toFixed(2)
              }}<a-icon type="ordered-list" style="margin-left: 3px; color: #04cb94"
            /></span>
          </a-popover>
        </template>

        <template slot="operation" slot-scope="operation, record">
          <span class="operation" @click="lookDetail(record)">详情</span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import storage from 'store';

import { queryOrderList, queryCreatorList, getTreeList, exchangeRateNew } from '@/api/headTeacher';

export default {
  name: 'corderIndex',
  data() {
    return {
      remittance: undefined,
      remittanceArr: [
        { label: '是', value: true },
        { label: '否', value: false },
      ],
      exChangeList: {},
      currencyList: ['EUR', 'GBP', 'SGD', 'HKD'],
      orderIds: [],
      paymentMethod: undefined,

      currencyColumns: [
        {
          title: '订单金额',
          dataIndex: 'targetValue',
          key: 'targetValue',
        },
        {
          title: '币种',
          dataIndex: 'currency',
          key: 'currency',
        },
      ],
      deliveryStatus: [
        { label: '无需发货', value: 'NO_NEED_TO_SHIP' },
        { label: '待确认', value: 'PENDING_CONFIRM' },
        { label: '待发货', value: 'PENDING' },
        { label: '已发货', value: 'SENT' },
        { label: '已取消', value: 'CANCEL' },
        { label: '打包中', value: 'WAITING' },
        { label: '已签收', value: 'FINISH' },
      ],
      payMethodArr: [
        { label: 'Latipay', value: 'LATIPAY' },
        { label: 'Airwallex', value: 'AIRWALLEX' },
        { label: 'Paypal', value: 'PAYPAL' },
        { label: 'Klarna', value: 'KLARNA' },
        { label: 'Stripe', value: 'STRIPE' },
        { label: '转账', value: 'REMITTANCE' },
        { label: '支付宝', value: 'ALIPAY' },
        { label: '微信', value: 'WECHAT' },
        { label: '信用卡', value: 'CARD' },
        { label: '银联', value: 'UPOP' },
        { label: 'ATOME', value: 'ATOME' },
        { label: '零元', value: 'ZERO' },
        { label: '其它', value: 'OTHER' },
      ],
      approvalStatus: [
        { label: '审核中', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
        { label: '已撤销', value: 'CANCEL' },
      ],
      tabsList: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
        { label: '新生报班', value: 'NEW_STU_REPORT_CLASS' },
        { label: '续费', value: 'RENEW' },
        { label: '补升', value: 'MAKE_UP' },
        { label: '赠课时', value: 'HANDSEL_COURSE' },
      ],
      orderStatusArray: [
        { label: '待支付', value: 'PENDING_PAYMENT' },
        { label: '已支付', value: 'PAYMENT_RECEIVED' },
        { label: '已支付待确认', value: 'CONFIRMED' },
        { label: '已取消', value: 'CANCELLED' },
      ],
      pageNum: 1,
      tableSorter: {},
      tags: [],
      studentCode: undefined,
      orderNumber: undefined,
      orderStatusSet: [],
      deptId: undefined,
      customerId: undefined,
      expired: undefined,
      refundStatus: undefined,
      whenCreatedFrom: null,
      whenCreatedTo: null,
      queryClassDateArr: [],
      creatorList: [],
      treeData: [],
      orderStatusArr: [
        { label: '待支付', value: 'PENDING_PAYMENT' },
        { label: '已支付', value: 'PAYMENT_RECEIVED' },
        { label: '已支付待确认', value: 'CONFIRMED' },
        { label: '已取消', value: 'CANCELLED' },
      ],
      orderTypeArr: [
        { label: '学生续费', value: '续费' },
        { label: '学生补升', value: '补升' },
        { label: '定金', value: '定金' },
        { label: '新生报班', value: '新生报班' },
        { label: '国际运费', value: '国际运费' },
        { label: '学员升级', value: '学员升级' },
        { label: '活动寄送', value: '活动寄送' },
        { label: '竞赛代报名', value: '竞赛代报名' },
      ],
      paymentExpirationStatus: [
        { label: '已过期', value: true },
        { label: '未过期', value: false },
      ],
      refundStatusArray: [
        { label: '待退款', value: 'PENDING' },
        { label: '退款处理中', value: 'PROCESSING' },
        { label: '已退款', value: 'OK' },
        { label: '退款失败', value: 'FAILED' },
      ],

      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '订单号',
          dataIndex: 'orderNumber',
          key: 'orderNumber',
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  this.lookDetail(record);
                },
              },
            };
          },
        },
        {
          title: '订单学员',
          dataIndex: 'studentCode',
          key: 'studentCode',
          scopedSlots: { customRender: 'studentCode' },
        },

        {
          title: '订单类型',
          dataIndex: 'orderTags',
          key: 'orderTags',
        },
        {
          title: '商品标签',
          dataIndex: 'productTags',
          key: 'productTags',
          scopedSlots: { customRender: 'productTags' },
        },
        {
          title: '订单金额',
          dataIndex: 'money',
          key: 'money',
          scopedSlots: { customRender: 'money' },
        },
        {
          title: '购买课时/额度',
          dataIndex: 'purchaseCuQuota',
          key: 'purchaseCuQuota',
        },
        {
          title: '审核状态',
          dataIndex: 'approveStatus',
          key: 'approveStatus',
          scopedSlots: { customRender: 'approveStatus' },
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          key: 'orderStatus',
          scopedSlots: { customRender: 'orderStatus' },
        },
        {
          title: '支付方式',
          dataIndex: 'paymentMethod',
          key: 'paymentMethod',
          scopedSlots: { customRender: 'paymentMethod' },
        },
        {
          title: '寄送状态',
          dataIndex: 'logisticsStatus',
          key: 'logisticsStatus',
          scopedSlots: { customRender: 'logisticsStatus' },
        },
        {
          title: '截止有效期',
          dataIndex: 'expireTime',
          key: 'expireTime',
          sorter: true,
        },
        {
          title: '创建时间',
          dataIndex: 'whenCreated',
          key: 'whenCreated',
          sorter: true,
        },
        {
          title: '创建人',
          dataIndex: 'whoCreated',
          key: 'whoCreated',
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableData: [],
      current: 0,
      pageSize: 10,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },

      loading: false,
    };
  },
  computed: {},
  methods: {
    moment,
    currencyData(originItem, sourceCurrency, money, index) {
      const data = [];
      const params = [{ method: 'REMITTANCE', currencies: ['EUR', 'GBP', 'SGD', 'HKD'] }];
      exchangeRateNew(params, originItem.uuid).then((res) => {
        (res?.data?.content?.[0]?.rates || []).map((item) =>
          data.push({
            targetValue: item.target.value,
            currency: item.target.currency,
          }),
        );
        originItem.money.exChangeList = data;
        this.tableData.splice(index, 1, originItem);
      });
    },

    toStudentDetail(record) {
      this.$router.push({
        path: '/studentDetail',
        query: {
          uuid: record.studentId,
        },
      });
    },
    lookDetail(data) {
      const routeUrl = this.$router.resolve({
        path: '/orderIndex/orderDetail',
        query: {
          orderId: data.uuid,
          approveStatus: data.approveStatus,
          orderStatus: data.orderStatus,
          logisticsStatus: data.logisticsStatus,
          studentId: data.studentId,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    initTabsList(value, data) {
      const arr = data.filter((i) => i.value === value);
      return arr.length ? arr : [{}];
    },
    queryCreatorList(name) {
      const params = {
        fullName: name,
        pageSize: 50,
      };
      queryCreatorList(params).then((res) => {
        this.creatorList = res.data.content;
      });
    },
    onChangeTableDate(date) {
      if (date.length > 0) {
        this.whenCreatedFrom = date[0].format('YYYY-MM-DD');
        this.whenCreatedTo = date[1].format('YYYY-MM-DD');
      } else {
        this.whenCreatedFrom = null;
        this.whenCreatedTo = null;
      }
    },
    getTreeList() {
      getTreeList().then((res) => {
        this.treeData = res.data;
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },

    hrefStudent() {
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid: this.formInfoData.studentId },
      });
      window.open(routeUrl.href, '_blank');
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },

    onClose() {
      this.visible = false;
    },

    handleTableChange(data, filters, sorter) {
      this.tableSorter = sorter;
      this.pageSize = data.pageSize;
      this.pageNum = data.current;
      this.initPagination.current = data.current;

      this.initSearchData();
    },

    resetCondition() {
      this.orderNumber = undefined;
      this.studentCode = undefined;
      this.deptId = undefined;
      this.customerId = undefined;
      this.expired = undefined;
      this.refundStatus = undefined;
      this.orderStatusSet = [];
      this.queryClassDateArr = [];
      this.whenCreatedFrom = null;
      this.whenCreatedTo = null;
      this.paymentMethod = undefined;
      this.remittance = undefined;
      this.tags = [];
      this.pageNum = 1;
      this.pageSize = 10;
      this.initSearchData('search');
    },
    initSearchData(act) {
      this.tableData = [];
      this.loading = true;
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        orderIds: this.orderIds,
        paymentMethod: this.paymentMethod,
        studentCode: this.studentCode,
        deptId: this.deptId,
        expired: this.expired,
        refundStatus: this.refundStatus,
        customerId: this.customerId,
        whenCreatedFrom: this.whenCreatedFrom,
        whenCreatedTo: this.whenCreatedTo,
        remittance: this.remittance,
        pageNum: this.pageNum,

        pageSize: this.pageSize,
      };
      if (this.orderStatusSet.length > 0) {
        params.orderStatusSet = this.orderStatusSet;
      }
      if (this.tags.length > 0) {
        params.tags = this.tags;
      }
      if (this.orderNumber) {
        params.orderNumber = this.orderNumber;
      }
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableSorter.order) {
        params.orderBy = `${this.tableSorter.columnKey} ${sort} nulls last`;
      }

      queryOrderList(params)
        .then((res) => {
          const data = res.data.content;
          this.tableData = res.data.content;
          this.loading = false;
          data.map((item, index) => {
            this.currencyData(item, item.money.currency, item.money.value, index);
          });
          this.current = res.data.currentPage;
          this.pageSize = res.data.pageSize;
          this.initPagination.total = res.data.totalCount;
          this.initPagination.pageSize = res.data.pageSize;
          this.initPagination.current = res.data.currentPage;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.$route.query.orderIds) {
      this.orderIds = JSON.parse(this.$route.query.orderIds);
    }

    this.getTreeList();
    this.queryCreatorList();
  },
  mounted() {
    this.initSearchData();
  },
};
</script>

<style lang="less" scoped>
.table-wrap {
  padding: 20px;
  background: #fff;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.operation {
  color: #3372fe;
  cursor: pointer;
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.table-wrap {
  padding: 20px;
  background: #fff;
}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.task-info {
  .task-type {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      padding: 2px 10px;
      background-color: #f7f7f7;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
  .task-form {
    border-bottom: 1px solid #eee;
    .form-name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 10px;
        border-radius: 6px;
        margin: 0 10px;
      }
    }
    .pending-status {
      color: #ffbf00;
      background-color: rgba(255, 191, 0, 0.09);
    }
    .success-status {
      color: #00cf64;
      background-color: rgba(0, 207, 100, 0.09);
    }
    .refuse-status {
      color: #ff5353;
      background-color: rgba(255, 83, 83, 0.09);
    }
    .form-teacher {
      font-size: 16px;
      font-weight: 400;
      color: #999;
      line-height: 22px;
      margin-bottom: 20px;
      .dueDate {
        color: #ff5454;
      }
      span {
        color: #333;
      }
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .form-des {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .form-imgs {
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }
  }
  .courseInfo {
    padding: 20px 0;
    .course-info {
      padding: 20px 16px;
      background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
      border-radius: 20px;
      .course-name {
        font-size: 16px;
        font-weight: 600;
        color: #009cff;
        line-height: 22px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      .course-type {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
  .taskFormBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 700px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 10px 16px;
    background: '#fff';
    text-align: right;
    z-index: 1;
    img {
      margin: 0 10px 0 0;
    }
    .ant-btn-primary {
      // background-color: #009cff;
    }
  }
}
.apply-item {
  margin-bottom: 10px;
  display: flex;
}
.apply-label {
  color: rgba(31, 45, 61, 0.4);
  min-width: 43px;
}
.apply-content {
  color: #1f2d3d;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;

  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.line-wrap {
  margin-top: 20px;

  border-bottom: 1px solid #eee;
}
.apply-detail {
  margin-top: 20px;
}
.examine-item {
  margin-bottom: 14px;
  display: flex;
}
.examine-label {
  width: 80px;
}
.examine-content {
  flex: 1;
}
.trans-wrap {
  background: rgba(0, 0, 0, 0.02);
  height: 280px;
  display: flex;
}
.trans-itemWrap {
  flex: 1;
  padding: 10px 20px;
}
.trans-item {
  margin-top: 5px;
}
.trans-itemLabel {
}
</style>
